import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import thankyou from "../../src/assests/thankyou.png";

function Thankyou() {
  useEffect(() => {
    // Triggering the Google Ads conversion event
    window.gtag("event", "conversion", {
      send_to: "AW-934679256/XXl5CMPnko0CENil2L0D",
    });

    // Redirect after 3 seconds
    const timer = setTimeout(() => {
      window.location.assign("/");
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="container" style={{ height: "80vh" }}>
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-4 p-3" style={{ borderRadius: "5px" }}>
          <div
            className="d-flex"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <img
              src={thankyou}
              alt="Thank you"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
          <div
            className="poppins-semibold text-center mt-3"
            style={{ fontSize: "20px" }}
          >
            Thank you! Your booking is Confirmed. <br />
            Enjoy our services with us!
          </div>
        </div>
      </div>
    </div>
  );
}

export default Thankyou;
